import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_39b427ad from 'nuxt_plugin_plugin_39b427ad' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_vuesocialsharingplugin_0ee529bc from 'nuxt_plugin_vuesocialsharingplugin_0ee529bc' // Source: ./vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_55cd67eb from 'nuxt_plugin_cookieuniversalnuxt_55cd67eb' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_gtm_186fac4e from 'nuxt_plugin_gtm_186fac4e' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_axios_f90aa37a from 'nuxt_plugin_axios_f90aa37a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_ssr_4168d999 from 'nuxt_plugin_ssr_4168d999' // Source: ../plugins/ssr.js (mode: 'all')
import nuxt_plugin_luxon_2b0efafd from 'nuxt_plugin_luxon_2b0efafd' // Source: ../plugins/luxon.js (mode: 'all')
import nuxt_plugin_axios_397e53b5 from 'nuxt_plugin_axios_397e53b5' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_cms_25c73702 from 'nuxt_plugin_cms_25c73702' // Source: ../plugins/cms.js (mode: 'all')
import nuxt_plugin_clerk_f06db114 from 'nuxt_plugin_clerk_f06db114' // Source: ../plugins/clerk.js (mode: 'all')
import nuxt_plugin_hyperUtility_2919c823 from 'nuxt_plugin_hyperUtility_2919c823' // Source: ../plugins/hyperUtility.js (mode: 'all')
import nuxt_plugin_hyperRemote_d7fbac4e from 'nuxt_plugin_hyperRemote_d7fbac4e' // Source: ../plugins/hyperRemote.js (mode: 'all')
import nuxt_plugin_hyperItem_5de41c28 from 'nuxt_plugin_hyperItem_5de41c28' // Source: ../plugins/hyperItem.js (mode: 'all')
import nuxt_plugin_hyperCart_73f6dabf from 'nuxt_plugin_hyperCart_73f6dabf' // Source: ../plugins/hyperCart.js (mode: 'all')
import nuxt_plugin_hyperUser_4a262634 from 'nuxt_plugin_hyperUser_4a262634' // Source: ../plugins/hyperUser.js (mode: 'all')
import nuxt_plugin_hyperLayout_147ae716 from 'nuxt_plugin_hyperLayout_147ae716' // Source: ../plugins/hyperLayout.js (mode: 'all')
import nuxt_plugin_hyperElement_5ed2deda from 'nuxt_plugin_hyperElement_5ed2deda' // Source: ../plugins/hyperElement.js (mode: 'all')
import nuxt_plugin_hyperProduct_16bf39c0 from 'nuxt_plugin_hyperProduct_16bf39c0' // Source: ../plugins/hyperProduct.js (mode: 'all')
import nuxt_plugin_hyperBlog_51a5befd from 'nuxt_plugin_hyperBlog_51a5befd' // Source: ../plugins/hyperBlog.js (mode: 'all')
import nuxt_plugin_hyperSeo_b93b90dc from 'nuxt_plugin_hyperSeo_b93b90dc' // Source: ../plugins/hyperSeo.js (mode: 'all')
import nuxt_plugin_gmap_65d80dce from 'nuxt_plugin_gmap_65d80dce' // Source: ../plugins/gmap.js (mode: 'client')
import nuxt_plugin_oAuth_c1d8ac58 from 'nuxt_plugin_oAuth_c1d8ac58' // Source: ../plugins/oAuth.js (mode: 'client')
import nuxt_plugin_utility_27ff1402 from 'nuxt_plugin_utility_27ff1402' // Source: ../plugins/utility.js (mode: 'client')
import nuxt_plugin_analytics_cc34deb6 from 'nuxt_plugin_analytics_cc34deb6' // Source: ../plugins/analytics.js (mode: 'client')
import nuxt_plugin_reviews_003260f0 from 'nuxt_plugin_reviews_003260f0' // Source: ../plugins/reviews.js (mode: 'client')
import nuxt_plugin_observeclient_4791def4 from 'nuxt_plugin_observeclient_4791def4' // Source: ../plugins/observe.client.js (mode: 'client')
import nuxt_plugin_paypal_bf6c62a0 from 'nuxt_plugin_paypal_bf6c62a0' // Source: ../plugins/paypal.js (mode: 'client')
import nuxt_plugin_integrationsgooglePlaces3bba2f34_5e1faf58 from 'nuxt_plugin_integrationsgooglePlaces3bba2f34_5e1faf58' // Source: ./integrations.googlePlaces.3bba2f34.js (mode: 'all')
import nuxt_plugin_integrationsjsLoad39734d88_3f8cd513 from 'nuxt_plugin_integrationsjsLoad39734d88_3f8cd513' // Source: ./integrations.jsLoad.39734d88.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"debounceWait":250,"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"shopster","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":" ","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[],"script":[{"src":"https:\u002F\u002Faccounts.google.com\u002Fgsi\u002Fclient","async":true,"defer":true},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_39b427ad === 'function') {
    await nuxt_plugin_plugin_39b427ad(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_0ee529bc === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_0ee529bc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_55cd67eb === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_55cd67eb(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_186fac4e === 'function') {
    await nuxt_plugin_gtm_186fac4e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_f90aa37a === 'function') {
    await nuxt_plugin_axios_f90aa37a(app.context, inject)
  }

  if (typeof nuxt_plugin_ssr_4168d999 === 'function') {
    await nuxt_plugin_ssr_4168d999(app.context, inject)
  }

  if (typeof nuxt_plugin_luxon_2b0efafd === 'function') {
    await nuxt_plugin_luxon_2b0efafd(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_397e53b5 === 'function') {
    await nuxt_plugin_axios_397e53b5(app.context, inject)
  }

  if (typeof nuxt_plugin_cms_25c73702 === 'function') {
    await nuxt_plugin_cms_25c73702(app.context, inject)
  }

  if (typeof nuxt_plugin_clerk_f06db114 === 'function') {
    await nuxt_plugin_clerk_f06db114(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperUtility_2919c823 === 'function') {
    await nuxt_plugin_hyperUtility_2919c823(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperRemote_d7fbac4e === 'function') {
    await nuxt_plugin_hyperRemote_d7fbac4e(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperItem_5de41c28 === 'function') {
    await nuxt_plugin_hyperItem_5de41c28(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperCart_73f6dabf === 'function') {
    await nuxt_plugin_hyperCart_73f6dabf(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperUser_4a262634 === 'function') {
    await nuxt_plugin_hyperUser_4a262634(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperLayout_147ae716 === 'function') {
    await nuxt_plugin_hyperLayout_147ae716(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperElement_5ed2deda === 'function') {
    await nuxt_plugin_hyperElement_5ed2deda(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperProduct_16bf39c0 === 'function') {
    await nuxt_plugin_hyperProduct_16bf39c0(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperBlog_51a5befd === 'function') {
    await nuxt_plugin_hyperBlog_51a5befd(app.context, inject)
  }

  if (typeof nuxt_plugin_hyperSeo_b93b90dc === 'function') {
    await nuxt_plugin_hyperSeo_b93b90dc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gmap_65d80dce === 'function') {
    await nuxt_plugin_gmap_65d80dce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_oAuth_c1d8ac58 === 'function') {
    await nuxt_plugin_oAuth_c1d8ac58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_utility_27ff1402 === 'function') {
    await nuxt_plugin_utility_27ff1402(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analytics_cc34deb6 === 'function') {
    await nuxt_plugin_analytics_cc34deb6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_reviews_003260f0 === 'function') {
    await nuxt_plugin_reviews_003260f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_observeclient_4791def4 === 'function') {
    await nuxt_plugin_observeclient_4791def4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_paypal_bf6c62a0 === 'function') {
    await nuxt_plugin_paypal_bf6c62a0(app.context, inject)
  }

  if (typeof nuxt_plugin_integrationsgooglePlaces3bba2f34_5e1faf58 === 'function') {
    await nuxt_plugin_integrationsgooglePlaces3bba2f34_5e1faf58(app.context, inject)
  }

  if (typeof nuxt_plugin_integrationsjsLoad39734d88_3f8cd513 === 'function') {
    await nuxt_plugin_integrationsjsLoad39734d88_3f8cd513(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
